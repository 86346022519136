@import "src/assets/styles/variables.scss";
@import "src/assets/styles/global-style.scss";

.text-hover {
  transition: $trn-dur-1 color;
}

.text-hover:hover {
  color: $title-clr;
}

.form-inline-controls {
  @include flex-between;

  width: 100%;
}

.fs-control {
  background-color: $main-bg-clr;
  border-radius: 4px;
  transition: 300ms all;
  border: 1px solid $form-field-brd-color;
}

.disabled-filter {
  filter: brightness(0.6) grayscale(1) !important;
}

.readonly-control {
  cursor: default !important;
  pointer-events: none !important;
}

.fs-disabled {
  @extend .disabled-filter, .readonly-control;
}

.fs-el-deactive {
  filter: brightness(0.8) grayscale(1) !important;
}

.fs-input-field-readonly {
  background-color: transparent !important;
  border: none !important;
}

.x-rotate-0 {
  transform: rotateX(0deg);
}

.x-rotate-180 {
  transform: rotateX(180deg);
}

.clear-btn {
  min-width: 0px !important;
  padding: 0 !important;
}

//------------Button styles--------------
button.card-action-button {
  @include bg-contain;

  width: 200px !important;
  height: 50px !important;
  font-size: 1.25rem;
  text-transform: uppercase;
  background-image: url("#{$svg-path}/Rectangle 4290.svg") !important;
  cursor: pointer;
}
//--------------------------------------

//-----------a HTML Tag styles-----------
.link {
  font-size: 1rem;
  font-weight: 400;
  color: $link-clr;
}

.mask-link {
  @include float-layer;
  @include full-size;
}
//------------------------------------

//----------Browser scroll styles-------
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $gray-3-clr;
  width: 8px;
}
//--------------------------------------

h3.title {
  color: $title-clr;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

//------------------FS Cards-------------------
.fs-card {
  .fs-card-footer {
    @include flex-center;

    width: 100%;
    position: absolute;
    bottom: 40px;
  }

  .fs-card-quick-link-container {
    @include flex-evenly;
    @include flex-wrap;

    .fs-card-quick-link {
      margin-bottom: 22px;
    }
  }
}

fs-simple-card {
  fs-simple-card-body {
    .venue-card-label {
      @include float-layer;
      @include flex-start;

      padding: 11px 10px;
      font-family: inherit;

      span {
        font-family: inherit;
        font-weight: 700;
        font-size: 1rem;
        color: $title-clr;
        text-transform: uppercase;
      }
    }

    .veneu-events {
      @include flex-center;
      @include flex-col;

      position: absolute;
      bottom: 6px;
      right: 12px;
      z-index: 1;

      span:nth-child(1) {
        color: $primary-clr;
        font-size: 1.5rem;
        font-weight: 700;
        height: 26px;
        font-family: "Stratos-Bold";
      }

      span:nth-child(2) {
        font-size: 0.875rem;
        color: $gray-4-clr;
      }
    }
  }
}

.fav-container {
  @include flex-center;

  width: 100%;

  .fav {
    @include bg-view;

    width: 76px;
    height: 25px;
    background-image: url("#{$svg-path}/FAV-Del.svg");
  }
}
//--------------------------------------

//-------------Venue info-------------
:host ::ng-deep {
  .venue-summ {
    @include flex-between;

    align-items: flex-start;
    width: 100%;
    position: relative;

    .venue-profile {
      @include flex-start;

      width: 44%;
      align-items: stretch;

      img {
        width: 124px;
        height: 124px;
        margin-bottom: 10px;
      }

      .venue-name {
        @include flex-start;
        @include flex-col;

        margin-inline-start: 15px;
        align-items: flex-start;

        .venue-title {
          @include text-overflow;
        }

        .venue-title,
        fs-list-selector h4 {
          @extend .text-overflow;

          color: $title-clr !important;
          font-size: 1.25rem !important;
          font-weight: 700;
          max-width: 20rem;
        }

        .venue-title,
        .description {
          margin-bottom: 4px;
        }

        h3 {
          @extend .text-overflow;

          font-weight: 700;
          font-size: 1.5rem;
          max-width: 26rem;
          color: $white-clr;
          text-transform: uppercase;
        }

        h6 {
          font-weight: 400;
          font-size: 1.5rem;
          width: inherit;
          color: $title-clr;
        }

        .description {
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.6rem;
          width: inherit;
        }
      }
    }

    .event-info {
      @include flex-end;
      @include flex-col;

      width: 50%;
      align-items: flex-end;
      text-transform: uppercase;

      .popup-info {
        margin-inline-start: 8px;
        margin-bottom: -4px;
      }

      h2 {
        font-weight: 700;
        font-size: 2rem;
        color: $white-clr;
        max-width: 100%;

        a {
          @extend .text-overflow;
        }
      }

      p {
        color: $white-clr;
        font-size: 1.5rem;
      }

      // button {
      //   @include bg-view;

      //   margin-top: 4px;
      //   background-image: url("#{$svg-path}/mini-green-button.svg");
      //   width: 100px;
      //   height: 34px;
      //   font-size: 20px;
      //   font-weight: 400;
      // }

      & > .status {
        span {
          font-size: 1.625rem;
          text-transform: uppercase;
        }

        & > .completed {
          color: $danger-clr;
        }

        & > .inprogress {
          color: $green-1-clr;
        }
      }
    }

    .venue-details {
      max-width: 270px;
      text-align: end;

      & > * {
        overflow-wrap: break-word;
      }

      h3 {
        color: $title-clr;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 4px;
      }

      .description {
        font-size: 1.25rem;
        line-height: 1.6rem;
      }
    }
  }

  .actions-container {
    @include flex-between;

    align-items: stretch;

    .event-rounds {
      margin-bottom: 10px;

      & > h3 {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 39.36px;
        margin-bottom: 16px;
      }
    }

    & > div:first-child {
      width: 144px;
      margin-inline-end: 16px;
    }

    & > .actions {
      margin-inline-start: 16px;
      margin-top: 12px;

      & > .wrapper {
        width: 160px;

        & > button {
          @extend .uppercase;

          @include bg-auto;
          @include flex-center;

          height: 34px !important;
          width: 100%;
          font-size: 1.25rem;
          margin-bottom: 12px;

          // &.scorecard {
          //   @extend .bg-rect-blue;
          // }

          // &.eventinfo {
          //   @extend .bg-rect-yellow;
          // }

          // &.participants {
          //   @extend .bg-rect-black;
          // }
        }
      }
    }
  }
}
//----------------------------------------

//-----------Active scroll to element----
.fs-visited-el {
  backdrop-filter: contrast(0.8);
}
//------------------------------------

.event-rounds {
  @include flex-col;
  @include flex-center;

  width: 100%;

  .event-rounds-selector {
    fs-selector-field {
      .fs-selector-field {
        .fs-controls-overflow {
          & > button:first-child {
            span {
              color: $danger-clr !important;
              font-size: 1.375rem !important;
            }
          }
        }
      }
    }
  }

  .net-gross-switcher {
    @include flex-end;

    width: 100%;
  }
}

.scoring-switch {
  .fs-controls-gp-wrapper {
    border-radius: 100px;
    background-color: black;
    border: 1px solid $gray-14-clr;

    .label {
      span {
        font-size: 0.875rem;
      }
    }
  }

  .active-control {
    border-radius: 100px !important;
  }
}

.frame-search-field {
  .fs-field-wrapper {
    padding-block: 4px !important;
    padding-inline-start: 38px !important;
    padding-inline-end: 8px !important;

    input {
      font-size: 1.125rem !important;
      transform: none !important;
    }
  }
}

.leaderboard-table-strock {
  fs-table {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.leaderboard-table-wl {
  fs-table {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.fs-thead-isolated {
  @include flex-start;

  margin-bottom: 6px;
  align-items: stretch;
  background-color: #575961;
  height: 34px;
  border-bottom: none;

  & > div {
    @include flex-center;

    text-transform: unset;
    border: 1px solid #797979;
    padding-inline: 12px;

    div {
      @include flex-center;

      p {
        color: $gray-4-clr;
        font-weight: 400;
        font-size: 1.125rem;
        text-align: center;

        & > * {
          color: inherit;
        }
      }
    }
  }
}

.fs-table-ui-2 {
  width: 100%;

  table {
    border-collapse: separate !important;

    thead {
      tr {
        background-color: $gray-2-clr;
        height: 34px !important;
        border-bottom: none !important;

        th {
          text-transform: unset !important;
          border: 1px solid #797979;
          padding-inline: 12px;

          p {
            color: $gray-4-clr;
            font-weight: 400;
            font-size: 1.125rem;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }

    tbody {
      tr {
        background-color: $gray-10-clr;
        height: 36px;

        td {
          border: 1px solid $gray-9-clr;
          padding-inline: 12px;

          p {
            font-size: 1.125rem;
            color: $gray-5-clr;
            //padding-inline: 12px;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }
  }
}

.fs-table-ui-3 {
  @extend .fs-table-ui-2;

  .header-utils {
    background-color: $gray-9-clr;
    height: 40px;
    width: 100%;
  }
}

//------Players table in my events tab------
.fs-table-ui-4 {
  tbody {
    tr {
      background-color: $white-clr;
      border-bottom: 1px solid rgba(216, 218, 227, 1) !important;
      height: 56px !important;

      td {
        p {
          color: $main-bg-clr;
          font-weight: 700;
        }

        &:nth-child(2) {
          p {
            font-weight: 400;
          }
        }
      }
    }
  }
}
//-------------------------

.row-edit-ico {
  @extend .i-edit;
  @include bg-contain;

  width: 18px;
  height: 18px;
  display: inline-block;
  filter: brightness(0.8);
  margin-inline-end: 4px;
}

.events-list-table,
.scorecard-event-table {
  fs-table {
    th {
      padding-block: 4px;

      p {
        color: $gray-7-clr;
      }
    }
  }
}

.events-list-table {
  display: block;

  fs-table {
    th:not(:nth-child(1)),
    td:not(:nth-child(1)) {
      & > * {
        @include flex-center;
      }
    }

    tbody {
      tr {
        height: 60px;
      }
    }
  }

  .action-button-details {
    & > button {
      margin-inline-end: 8px;
      width: 125px;
      height: 36px;
    }
  }
}

app-event-players {
  .fs-table-wrapper {
    width: 100% !important;
  }
}

.scorecard-event-table {
  .fs-table-wrapper {
    width: 100% !important;
  }

  display: block;

  fs-table {
    tbody {
      tr {
        height: 60px;
      }
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.event-players-table {
  & > div {
    min-width: 1187px;
  }

  fs-table {
    th:nth-child(1),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    th:nth-child(8),
    td:nth-child(1),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8) {
      & > * {
        @include flex-center;
      }
    }

    td:nth-child(2) {
      & > * {
        @include flex-start;

        flex-wrap: wrap;

        p {
          max-width: calc(100% - 40px);
        }
      }
    }

    td:nth-child(3) {
      text-align: center;
    }
  }
}

.users-list-table {
  display: block !important;
  overflow-y: auto;

  fs-table {
    th:nth-child(1),
    th:nth-child(3),
    td:nth-child(1),
    td:nth-child(3) {
      & > * {
        @include flex-center;
      }
    }

    td:nth-child(1) {
      padding-inline: 0;
    }

    th:nth-child(1) {
      a,
      .th-container .th-sort-icon {
        display: none;
      }
    }
  }
}

.preferences-tab {
  .fs-controls-gp-wrapper {
    border: 1px solid $button-brd-clr;
    background-color: $main-bg-clr;
    border-radius: 4px;

    .fs-controls-overflow {
      height: 36px !important;

      button {
        span {
          font-family: "DavisSans-Bold";
        }
      }
    }
  }
}

.infinite-icon-34 {
  @include bg-view;

  width: 44px;
  height: 20px;
  filter: contrast(0.1);
  background-image: url("#{$image-path}/Infinite.png");
}

.captain-mark {
  @include flex-center;

  font-family: "Stratos-Bold";
  font-size: 1.375rem !important;
  font-weight: 700;
  border: 1px solid $button-brd-clr;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  margin-inline-end: 8px;
}

.fs-default-input {
  background-color: transparent;
  border: none;
  box-sizing: unset;
  outline: none;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  width: inherit;
  text-align: center;
  height: 100%;
}

.fs-outline-circular {
  border-radius: 100%;
}

.fs-outline {
  padding: 4px;
  border: 2px solid;
  border-color: inherit;
}

.fs-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 99;

  &.backdrop {
    -moz-backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    -o-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: #000000a1;
    opacity: 0;
    transition: opacity 150ms;
  }

  .child-wrapper {
    @include w-fit-content;
    @include h-fit-content;

    position: relative;
  }

  .layout {
    background-color: black;
    border-radius: 4px;
    overflow: hidden;

    .fs-selector-field {
      margin-inline: 0;

      .fs-controls-gp-wrapper {
        width: 100%;

        .control-item {
          .label {
            border: 1px solid;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.form-valid,
.form-error {
  @include bg-view;

  width: 20px;
  height: 20px;

  span {
    font-size: 0.813rem;
  }
}

.form-valid {
  background-image: url("#{$icon-path}/checked.svg");
}

.form-error {
  background-image: url("#{$icon-path}/form-error-20.svg");
}

.close {
  @include bg-view;

  background-image: url("#{$svg-path}/close.svg");
  position: absolute;
  right: 15px;
  top: 27px;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.fs-small-message {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  display: flex;

  &.error {
    color: $error-light;

    &::before {
      @extend .i-cir-error;
      @include bg-auto;

      margin-inline-end: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.success {
    color: white;

    &::after {
      @extend .i-checked;
      @include bg-auto;

      margin-inline-start: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.normal {
    color: $gray-clr;
  }
}

.fs-league-table-utils {
  @include flex-end;

  width: calc(100% - 114px);
  margin-bottom: 4px;

  & > div {
    @include flex-center;

    span {
      font-size: 1rem;
    }

    .info {
      @include bg-view;

      width: 24px;
      height: 24px;
      background-image: url("#{$icon-path}/info-cir-transparent-24-yellow.svg");
    }
  }

  & > div:last-child {
    & > * {
      margin-inline-start: 8px;
    }
  }
}