html {
  max-height: 100vh;
  overflow: hidden !important;
  top: unset !important;
}

body {
  margin: 0;
  overflow: auto;
  position: relative;

  & * {
    font-family: "DavisSans-Regular";
    letter-spacing: unset;
    color: white;
    margin: 0;
    cursor: default;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

button {
  box-sizing: none;
  border: none;
  background-color: transparent;
}

button,
button *,
a,
a > * {
  cursor: pointer;
}

a {
  display: block;
  text-decoration: none;
}

p {
  & *:not(b) {
    font-size: inherit !important;
    font-weight: 400;
  }
}

main {
  background-color: $main-bg-clr;
}

input {
  cursor: text;
}

form {
  width: 100%;
}

i {
  display: inline-block;
}
