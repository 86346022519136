$home-users-table-column-widths: (
  lg: (
    1: 28px,
    2: 300px,
    3: 400px,
    4: 36px,
  ),
  md: (
    1: 28px,
    2: 24vw,
    3: 34vw,
    4: 36px,
  ),
);

$event-list-table-column-widths: (
  lg: (
    1: 220px,
    2: 120px,
    3: 60px,
    4: 116px,
    5: 116px,
    6: 90px,
    7: 90px,
    8: 150px,
    9: 160px,
  ),
  md: (
    1: 150px,
    2: 100px,
    3: 60px,
    4: 116px,
    5: 116px,
    6: 90px,
    7: 70px,
    8: 120px,
    9: 160px,
  ),
);

$event-table-column-widths: (
  lg: (
    1: 400px,
    2: 70px,
    3: 120px,
    4: 120px,
    5: 100px,
    6: 90px,
    7: 90px,
  ),
  md: (
    1: 250px,
    2: 70px,
    3: 120px,
    4: 120px,
    5: 90px,
    6: 90px,
    7: 70px,
  ),
);

$leaderboard-strock-table-column-widths: (
  xl: (
    1: 70px,
    2: 70px,
    3: 278px,
    4: 69px,
    5: 60px,
    6: 60px,
    7: 60px,
    8: 180px,
  ),
  lg: (
    1: 60px,
    2: 70px,
    3: 220px,
    4: 69px,
    5: 60px,
    6: 60px,
    7: 60px,
    8: 130px,
  ),
  md: (
    1: 50px,
    2: 60px,
    3: 180px,
    4: 60px,
    5: 60px,
    6: 60px,
    7: 60px,
    8: 120px,
  ),
);

$leaderboard-wl-table-column-widths: (
  xl: (
    1: 70px,
    2: 70px,
    3: 278px,
    4: 69px,
    5: 60px,
    6: 60px,
    7: 180px,
  ),
  lg: (
    1: 60px,
    2: 70px,
    3: 220px,
    4: 69px,
    5: 60px,
    6: 60px,
    7: 130px,
  ),
  md: (
    1: 50px,
    2: 60px,
    3: 180px,
    4: 60px,
    5: 60px,
    6: 60px,
    7: 120px,
  ),
);

$scorecard-event-table-column-widths: (
  lg: (
    1: 360px,
    2: 110px,
    3: 110px,
    4: 100px,
    5: 80px,
    6: 150px,
    7: 150px,
  ),
  md: (
    1: 180px,
    2: 100px,
    3: 100px,
    4: 100px,
    5: 80px,
    6: 150px,
    7: 100px,
  ),
);

$event-players-table-column-widths: (
  lg: (
    1: 70px,
    2: 254px,
    3: 384px,
    4: 160px,
    5: 72px,
    6: 110px,
    7: 36px,
  ),
  md: (
    1: 70px,
    2: 254px,
    3: 384px,
    4: 160px,
    5: 72px,
    6: 110px,
    7: 36px,
  ),
);

//====================================================

@media screen and (min-width: 1200px) {
  html {
    font-size: 16px;

    app-home {
      .users-list {
        & > div {
          max-width: 870px;

          .users-list-table {
            @include set-fs-table-columns-width(
              map-get($home-users-table-column-widths, lg)
            );
          }
        }
      }
    }

    app-event {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-list-table-column-widths, lg)
        );

        // .fs-table-wrapper {
        //   max-height: calc(100vh - 200px);
        // }
      }
    }

    app-event-results {
      .leaderboard-table-strock {
        @include set-fs-table-columns-width(
          map-get($leaderboard-strock-table-column-widths, xl)
        );
      }

      .leaderboard-table-wl {
        @include set-fs-table-columns-width(
          map-get($leaderboard-wl-table-column-widths, xl)
        );
      }
    }

    app-player-scores {
      .scorecard-event-table {
        @include set-fs-table-columns-width(
          map-get($scorecard-event-table-column-widths, lg)
        );
      }
    }

    app-event-players {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-table-column-widths, lg)
        );
      }
    }

    app-event-players {
      .event-players-table {
        overflow-x: hidden;

        @include set-fs-table-columns-width(
          map-get($event-players-table-column-widths, lg)
        );
      }
    }
  }
}

@media screen and (min-width: 1800px) {
  .nav-bar {
    width: 1800px !important;
  }
}

@media screen and (max-width: 1199px) {
  html {
    font-size: 16px;

    app-home {
      .users-list {
        & > div {
          max-width: 870px;

          .users-list-table {
            @include set-fs-table-columns-width(
              map-get($home-users-table-column-widths, lg)
            );
          }
        }
      }
    }

    app-event {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-list-table-column-widths, lg)
        );

        // .fs-table-wrapper {
        //   max-height: calc(100vh - 200px);
        // }
      }
    }

    app-event-results {
      .leaderboard-table-strock {
        @include set-fs-table-columns-width(
          map-get($leaderboard-strock-table-column-widths, lg)
        );
      }

      .leaderboard-table-wl {
        @include set-fs-table-columns-width(
          map-get($leaderboard-wl-table-column-widths, lg)
        );
      }
    }

    app-player-scores {
      .scorecard-event-table {
        @include set-fs-table-columns-width(
          map-get($scorecard-event-table-column-widths, lg)
        );
      }
    }

    app-event-players {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-table-column-widths, lg)
        );
      }
    }

    app-event-players {
      .event-players-table {
        overflow-x: auto;

        @include set-fs-table-columns-width(
          map-get($event-players-table-column-widths, lg)
        );
      }
    }
  }
}

@media screen and (max-width: 991px) {
  html {
    font-size: 14px;

    .nav-bar-bg-wrapper {
      #top-navbar {
        width: auto !important;
        height: 50px !important;
      }
    }

    .nav-container {
      .nav-items-wrapper {
        ul {
          padding-inline-start: 20px !important;

          li {
            margin-inline-end: 20px !important;

            a:not(.active) {
              font-size: 1.25rem !important;
            }
          }
        }
      }
    }

    app-home {
      .users-list {
        & > div {
          max-width: 88vw;

          .users-list-table {
            @include set-fs-table-columns-width(
              map-get($home-users-table-column-widths, md)
            );

            fs-table {
              @include w-min-content;
            }
          }
        }
      }
    }

    app-event {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-list-table-column-widths, md)
        );

        // .fs-table-wrapper {
        //   max-height: calc(100vh - 200px);
        // }
      }
    }

    app-event-results {
      .leaderboard-table-strock {
        @include set-fs-table-columns-width(
          map-get($leaderboard-strock-table-column-widths, md)
        );
      }

      .leaderboard-table-wl {
        @include set-fs-table-columns-width(
          map-get($leaderboard-wl-table-column-widths, md)
        );
      }
    }

    app-player-scores {
      .scorecard-event-table {
        @include set-fs-table-columns-width(
          map-get($scorecard-event-table-column-widths, md)
        );
      }
    }

    app-event-players {
      .events-list-table {
        @include set-fs-table-columns-width(
          map-get($event-table-column-widths, md)
        );
      }
    }

    app-event-players {
      .event-players-table {
        overflow-x: auto;

        @include set-fs-table-columns-width(
          map-get($event-players-table-column-widths, md)
        );
      }
    }
  }
}

//این سایز استثنا هست
@media screen and (max-width: 874px) {
  html {
    app-event-details {
      .content {
        flex-direction: column;
        align-items: stretch !important;
        justify-content: flex-start !important;
        overflow-y: auto;
        overflow-x: hidden;
        //max-height: calc(100vh - 200px);

        .event-image-wrapper {
          align-items: center !important;
          margin-bottom: 20px;
        }

        .event-image-wrapper,
        .event-form {
          width: auto !important;
        }

        .quill-editor-container {
          quill-editor {
            width: 100% !important;
          }
        }

        &.event-preferences {
          & > div {
            width: auto !important;
          }
        }
      }
    }

    app-event-results {
      .actions-container {
        flex-direction: column-reverse;
        align-items: center !important;

        .actions {
          width: 100%;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
        }
      }
    }
  }
}

//این سایز استثنا هست
@media screen and (max-width: 868px) {
  html {
    font-size: 14px;

    .nav-container {
      .nav-items-wrapper {
        ul {
          padding-inline-start: 10px !important;
          flex-direction: column !important;
          margin-top: 20px !important;

          li {
            margin-bottom: 16px;
          }
        }
      }
    }

    .main {
      & > .container {
        margin-top: 30px;

        .frame {
          .frame-body {
            padding-top: 40px !important;

            .venue-summ {
              padding-inline: 0 !important;
            }
          }
        }
      }
    }

    // app-event {
    //   .events-list-table {
    //     .fs-table-wrapper {
    //       //max-height: calc(100vh - 240px);
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .list-selector-header {
    height: 20px;
  }

  fs-toast {
    .fs-toast-container {
      .fs-toast {
        width: calc(100vw - 40px) !important;
      }
    }
  }
}

@media screen and (max-width: 420px) {
}
