@mixin d-flex {
  display: flex;
}

@mixin flex-center {
  @include d-flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  @include d-flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  @include d-flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-between {
  @include d-flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around {
  @include d-flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-evenly {
  @include d-flex;
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}

@mixin flex-col {
  flex-direction: column;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin bg-view {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

@mixin bg-auto {
  @include bg-view;
  background-size: auto;
}

@mixin bg-contain {
  @include bg-view;
  background-size: contain;
}

@mixin bg-full {
  @include bg-view;
  background-size: 100%;
}

@mixin bg-cover {
  @include bg-view;
  background-size: cover;
}

@mixin full-size {
  width: 100%;
  height: 100%;
}

@mixin float-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@mixin text-overflow {
  @extend .text-overflow;
}

@mixin w-fit-content {
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: -ms-fit-content;
  width: -o-fit-content;
}

@mixin w-min-content {
  width: min-content;
  width: -moz-min-content;
  width: -webkit-min-content;
  width: -ms-min-content;
  width: -o-min-content;
}

@mixin w-max-content {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -ms-max-content;
  width: -o-max-content;
}

@mixin w-fill-available {
  //width: fill-available;
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
}

@mixin h-fit-content {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: -ms-fit-content;
  height: -o-fit-content;
}

@mixin h-min-content {
  height: min-content;
  height: -moz-min-content;
  height: -webkit-min-content;
  height: -ms-min-content;
  height: -o-min-content;
}

@mixin h-max-content {
  height: max-content;
  height: -moz-max-content;
  height: -webkit-max-content;
  height: -ms-max-content;
  height: -o-max-content;
}

@mixin h-fill-available {
  //height: fill-available;
  height: stretch;
  height: -webkit-fill-available;
  height: -moz-available;
}

@mixin set-fs-table-columns-width($widths, $waste: 20px) {
  fs-table {
    @each $name, $width in $widths {
      th:nth-child(#{$name}) {
        width: $width;
      }

      td:nth-child(#{$name}) {
        .text-content {
          max-width: calc($width - $waste);
        }
      }
    }
  }
}

.me-8 {
  margin-inline-end: 8px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-block-end: 8px;
}

.mb-16 {
  margin-block-end: 16px;
}

.mb-32 {
  margin-block-end: 32px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mx-8 {
  margin-inline: 8px;
}

.mx-24 {
  margin-inline: 24px;
}

.mx-36 {
  margin-inline: 36px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mt-8 {
  margin-block-start: 8px;
}

.mt-6 {
  margin-block-start: 6px;
}

.mt-16 {
  margin-block-start: 16px;
}

.mt-32 {
  margin-block-start: 32px;
}

.p-0 {
  padding: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.pos-rel {
  position: relative;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.full-width {
  width: 100%;
}

.light-font {
  font-family: "DavisSans-Light";
}

.bold-font {
  font-family: "DavisSans-Bold";
}

.italic-font {
  font-family: "DavisSans-Italic";
}

.regular-font {
  font-family: "DavisSans-Regular";
}

.medium-font {
  font-family: "DavisSans-Medium";
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.ico {
  @include bg-contain;
  display: inline-block;
}

.x10 {
  width: 10px;
  height: 10px;
}

.x16 {
  width: 16px;
  height: 16px;
}

.x18 {
  width: 18px;
  height: 18px;
}

.x24 {
  width: 24px;
  height: 24px;
}

.x28 {
  width: 28px;
  height: 28px;
}

.x32 {
  width: 32px;
  height: 32px;
}

.x36 {
  width: 36px;
  height: 36px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-0 {
  transform: rotate(0deg);
}

.overflow-y {
  overflow-y: auto;
  height: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block !important;
}

.text-wrap {
  overflow-wrap: break-word;
}

.multiline-text-overflow {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;

  * {
    cursor: inherit;
  }
}

.uppercase {
  text-transform: uppercase;
}

.full-opacity {
  opacity: 1 !important;
}

.description {
  font-size: 1.25rem;
  line-height: 1.6rem;

  .i-captain-logo {
    margin-bottom: -6px;
  }
}
