@font-face {
  font-family: "DavisSans-Bold";
  src: url("#{$font-path}/DavisSans-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-BoldItalic";
  src: url("#{$font-path}/DavisSans-BoldItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-Italic";
  src: url("#{$font-path}/DavisSans-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-Light";
  src: url("#{$font-path}/DavisSans-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-LightItalic";
  src: url("#{$font-path}/DavisSans-LightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-Medium";
  src: url("#{$font-path}/DavisSans-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-MediumItalic";
  src: url("#{$font-path}/DavisSans-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DavisSans-Regular";
  src: url("#{$font-path}/DavisSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

//--------Stratos font face---------
@font-face {
  font-family: "Stratos-Bold";
  src: url("#{$font-path}/Stratos-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-BoldItalic";
  src: url("#{$font-path}/Stratos-BoldItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-Italic";
  src: url("#{$font-path}/Stratos-Italic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-Light";
  src: url("#{$font-path}/Stratos-Light.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-LightItalic";
  src: url("#{$font-path}/Stratos-LightItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-Medium";
  src: url("#{$font-path}/Stratos-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-MediumItalic";
  src: url("#{$font-path}/Stratos-MediumItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Stratos-Regular";
  src: url("#{$font-path}/Stratos-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
//----------------------------------
